$red-color: #f22d2d;

//@media screen and (max-width: 1820px) {
//  .map {
//    //.map-bg {
//    //  display: none;
//    //}
//    .map-gradient {
//      height: 100%;
//    }
//  }
//}
@media screen and (max-width: 1600px) {
  .blue-bg:before, .blue-bg:after {
    display: none;
  }
}
@media screen and (max-width: 1450px){
  .line-items {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .production-circle {
    width: 35%;
  }
  .slick-next {
    right: -100px;
  }
  .slick-prev {
    left: -100px;
  }
}
@media screen and (max-width: 1400px){
  footer .frame-bottom:before {
    left: -4px;
  }
}
@media screen and (max-width: 1330px){
  .partner-logo-wrapper {
    .partner-logo {
      width: 20%;
    }
  }
  .video-view-wrapper .video-view-item:after {
    display: none;
  }
}

@media screen and (max-width: 1250px) {
  .production-circle {
    transform: scale(0.9);
  }
}
@media screen and (min-width: 1199px){
  .circle-info-line {
    height: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: bottom center;
    &:first-child {
      .circle-info-block {
        position: relative;
        top: -75px;
      }

    }
    &:nth-child(2){
      transform: translateX(-50%) rotate(45deg);
      .circle-info-block {
        transform: rotate(-45deg);
        position: relative;
        left: 135px;
        top: -167px;
      }
    }
    &:nth-child(3){
      transform: translateX(-50%) rotate(90deg);
      .circle-info-block {
        transform: rotate(-90deg);
        position: relative;
        top: -214px;
      }
    }
    &:nth-child(4){
      transform: translateX(-50%) rotate(135deg);
      .circle-info-block {
        transform: rotate(-135deg);
        position: relative;
        top: -218px;
        left: -151px;
      }
    }
    &:nth-child(5){
      transform: translateX(-50%) rotate(180deg);
      .circle-info-block {
        transform: rotate(-180deg);
        position: relative;
        bottom: 90px;
        left: 25px;
      }
    }
    &:nth-child(6){
      transform: translateX(-50%) rotate(225deg);
      .circle-info-block {
        transform: rotate(-225deg);
        flex-direction: row-reverse;
        position: relative;
        left: 120px;
        top: -188px;
        .icon {
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
    &:nth-child(7){
      transform: translateX(-50%) rotate(270deg);
      .circle-info-block {
        transform: rotate(-270deg);
        flex-direction: row-reverse;
        position: relative;
        top: -221px;
        .icon {
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
    &:nth-child(8){
      transform: translateX(-50%) rotate(315deg);
      .circle-info-block {
        transform: rotate(-315deg);
        flex-direction: row-reverse;
        position: relative;
        left: -145px;
        top: -187px;
        .icon {
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .partner-logo-wrapper {
    .partner-logo {
      width: 25%;
    }
  }
  .partner-item {
    width: 50%;
    max-width: 500px;
    margin-bottom: 120px;
  }
  .partner-items-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  .production-circle {
    margin: 50px auto;
  }
  .circle-info-block {
    justify-content: flex-start;
    margin-left: 30%;
    margin-bottom: 20px;
  }
  .circle-line {
    display: none;
  }
  .production-circle-bg {
    top: 90px;
  }
  footer .main-menu {
    display: none;
  }
  .case-block {
    flex-wrap: wrap;
  }
  .video-docs {
    width: 100%;
    order: 1;
    margin-bottom: 40px;
    video {
      max-width: 600px;
      margin: 0 auto;
      display: block;
    }
  }
  .scenario-docs {
    order: 2;
    margin: 0 10px;
    width: 35%;
  }
  .contract-docs {
    order: 3;
    margin: 0 10px;
    width: 35%;
  }
  .partner-items-wrapper {
    margin-bottom: 0;
  }
  .image-bg img {
    max-width: 97vw;
  }
  .footer-logo {
    flex-direction: column;
  }
  footer .logo {
    bottom: 10px;
    margin-right: 0;
  }
  .footer-logo-text {
    margin-bottom: 10px;
  }
  .production-circle-title span:first-child {
    font-size: 100px;
  }
  .production-circle-title span:nth-child(2) {
    font-size: 30px;
    bottom: -51%;
    left: 7%;
  }
  .timeline {
    overflow: hidden;
  }
  .production-circle-bg {
    img {
      width: 100%;
    }
  }
  .timeline .timeline-gradient {
    bottom: 60px;
  }
  .map .map-bg:after {
    height: 200%;
  }
  .video-docs {
    .video-poster {
      max-width: 600px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 1024px) {
  .benefit-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  .benefit-item {
    width: 40%;
  }
  .stages .right-camera {
    z-index: 2;
  }
}

@media screen and (max-width: 992px) {
  .main-menu-wrapper {
    height: 0;
  }
  .city-row {
    flex-wrap: wrap;
    .city-item {
      width: 50%;
      margin-bottom: 20px;
    }
  }
  .lang-block, .contact-block {
    display: none;
  }
  .mobile-menu-btn {
    display: flex;
  }
  .partner-logo-wrapper {
    .partner-logo {
      width: 33.33%;
    }
  }
  .video-frame {
    display: none;
  }
  .main-menu {
    position: fixed;
    top: -100vh;
    left: 0;
    z-index: 9;
    background: linear-gradient(to bottom, rgba(15,19,27,1) 0%,rgba(1,0,0,1) 100%);
    flex-direction: column;
    width: 100vw;
    transition: all .5s;
    a {
      margin: 16px 37px 10px 27px;
    }
    a:after {
      display: none;
    }
  }
  body.mobile-menu {
    .main-menu {
      top: 100px;
    }
  }
  .video-wrapper {
    border: none;
  }
  .video {
    margin-top: 104px;
  }
  header {
    margin-bottom: 0;
    box-sizing: border-box;
    border-bottom: 3px solid $red-color;
  }
  header {
    position: fixed;
    z-index: 10;
    background: #0f131c;
    width: 100vw;
    left: 0;
    top: 0;
    padding: 20px;
  }
  .scenario-types {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &>span {
        margin-bottom: 5px;
      }
    }
  }
  .coast-wrapper {
    flex-wrap: wrap;
  }
  .coast-item {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 40px;
    border-right: none;
  }
  .stages-item-wrapper {
    width: 50%;
  }
  .timeline .timeline-gradient {
    top: 0;
    bottom: 125px;
  }
  .video-view-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  .video-view-item {
    width: 50%;
    margin-bottom: 30px;
  }
  .btn-row {
    margin: 40px auto 60px;
  }
  .slider-item-wrapper {
    padding-top: 38%;
  }
  .main-menu.fix-menu {
    top: -100vh;
  }
  .production-circle {
    min-width: 400px;
  }
  .footer-contacts {
    position: static;
  }
  .footer-copy {
    display: none;
  }
  .footer-contacts {
    padding-left: 40%;
  }
  footer {
    padding-bottom: 20px;
  }
  footer .frame-bottom {
    display: none;
  }
  footer .logo {
    margin-bottom: 20px;
  }
  .footer-logo-text {
    margin: 0 auto 20px;
  }
  .mobile-case {
    display: block;
  }
  .scenario-docs {
    width: 100%;
    margin: 0 0 20px 0;
    display: flex;
    justify-content: center;
    height: auto;
    .doc1, .doc2, .doc3 {
      position: static;
      box-shadow: none;
      margin: 0 10px;
      width: 50%;
      transform: none;
    }
  }
  .scenario-docs:before {
    display: none;
  }
  .contract-content {
    .doc1 {
      width: 100%;
      max-width: 170px;
      margin: 0 auto 20px;
    }
  }
  .act-content {
    .doc2 {
      width: 100%;
      max-width: 170px;
      margin: 0 auto 20px;
    }
  }
  .contract-docs {
    &:before, &:after {
      display: none;
    }
  }
  .contract-docs {
    height: 0;
    margin: 0;
  }
  .scenario-docs.three-doc {
    .doc1, .doc2 {
      width: 33.33%;
    }
    .doc3 {
      position: static;
      box-shadow: none;
      transform: none;
      width: 33.33%;
    }
  }
  .stages .stages-gradient {
    height: 110%;
  }
}

@media screen and (max-width: 768px) {
  .map .map-bg:after {
    top: 60%;
  }
  .scenario .scenario-bg img {
    max-width: 97vw;
  }
  .timeline .timeline-gradient {
    bottom: 250px;
  }
  .partner-logo-wrapper {
    .partner-logo {
      width: 50%;
    }
  }
  .partner-item {
    width: 100%;
    max-width: 300px;
    margin: 0 auto 120px;
  }
  .partner-items-wrapper {
    flex-direction: column;
  }
  .slider-item .video-title {
    font-size: 40px;
  }
  .slider-item .video-description {
    font-size: 28px;
  }
  .video-view-item {
    width: 90%;
    padding-left: 30%;
  }
  .section-title {
    font-size: 28px;
  }
  .benefit-item {
    width: 50%;
    margin-bottom: 40px;
  }
  .round-btn {
    font-size: 14px;
  }
  .timeline-row {
    display: none;
  }
  .timeline-slider, .timeline-btn-block {
    display: block;
  }
  .timeline-slider-footer {
    background: linear-gradient(to right, rgba(255, 255, 255, .01) 1%, rgba(239, 45, 45, 1) 50%, rgba(255, 255, 255, .01) 100%);
    //width: 100%;
    height: 10px;
    margin: 0 20px;
    position: relative;
    .circle-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #6c88c7;
    }
  }

  .timeline-slider-item {
    outline: none;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .icon {
      font-size: 60px;
      color: $red-color;
      text-align: center;
      margin-bottom: 20px;
    }
    .item-title {
      margin-bottom: 20px;
    }
  }
  .timeline-btn-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    .icon {
      font-size: 40px;
      color: #6c88c7;
      cursor: pointer;
      margin: 0 10px;
    }
  }
  .timeline-btn-play {
    .icomoon-play3 {
      display: block;
    }
    .icomoon-pause {
      display: none;
    }
  }
  .timeline-btn-play.play {
    .icomoon-play3 {
      display: none;
    }
    .icomoon-pause {
      display: block;
    }
  }
  .coast-btn {
    position: static;
    transform: none;
    margin: 0 auto;
  }
  .mobile-body-wrapper {
    display: none;
    padding: 15px 0;
  }
  .mobile-title-wrapper {
    background: #0f131c;
    width: 100%;
    padding: 15px 0;
    cursor: pointer;
  }
  .coast-item-time {
    margin-bottom: 0;
    &:after {
      content: "\e93c";
      font-family: icomoon;
      font-size: 12px;
      color: #526899;
      margin-left: 15px;
    }
  }
  .coast .coast-gradient {
    height: 160%;
  }
  .coast-item {
    padding: 0;
    margin: 0 auto 10px;
  }
  .coast-item.show {
    .mobile-title-wrapper {
      background: #6c88c7;
    }
    .coast-item-title {
      color: #040609;
    }
    .coast-item-time:after {
      display: inline-block;
      transform: rotate(180deg);
    }
  }
  .coast-wrapper {
    margin-bottom: 100px;
  }
  .coast-item-text {
    margin-top: 10px;
  }
  .communication-method .mail-block {
    display: none;
  }
  .communication-title {
    width: 100%;
    text-align: center;
  }
  .contract-docs:after {
    bottom: -40px;
  }
  .circle-info-block {
    margin-left: 10%;
  }
  .production {
    margin-bottom: 50px;
  }
  .btn-row {
    flex-direction: column;
    .btn:first-child {
      margin-bottom: 20px;
    }
  }
  .video-view-item {
    padding-left: 10%;
  }
  .benefit-item:nth-child(2) {
    margin-top: 0;
  }
  .slick-next {
    right: -90px;
  }
  .slick-prev {
    left: -90px;
  }
  .popup {
    width: 90%;
  }
  .case-description-text.short {
    max-height: 100px;
    overflow: hidden;
  }

  .map .map-bg {
    top: 60px;
  }
  .map .map-gradient {
    bottom: -100px;
  }
  .case-title {
    font-size: 18px;
    line-height: 28px;
  }
  .video-view-wrapper .video-view-item, .video-view-body {
    width: 100%;
  }
  .video-view-body {
    border-bottom: 1px solid $red-color;
    padding-bottom: 20px;
  }
  .video-view-body.last-video-item {
    border-bottom: 1px solid transparent;
  }
  .case-block {
    margin-bottom: 20px;
  }

  .stages-item-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }
  .stages .left-camera {
    display: none;
  }
  .stages-item-wrapper {
    background: #0f131c;
    width: 100%;
    max-width: 450px;
    margin: 0 auto 10px;
    padding-left: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    .stages-item {
      z-index: 3;
    }
    &:before {
      content: attr(data-count);
      font-family: mullerlight, sans-serif;
      position: absolute;
      z-index: 2;
      color: #080a10;
      font-size: 170px;
      right: 0;
      top: 0;
    }
  }
  .stage-time {
    margin-bottom: 20px;
  }
  .btn-row {
    position: relative;
    z-index: 2;
  }
}
@media screen and (max-width: 575px) {
  body {
    //overflow-x: hidden;
  }
  .container {
    padding: 0 10px;
  }
  .city-row {
    .city-item {
      width: 100%;
    }
  }
  .partner-logo-wrapper {
    .partner-logo {
      width: 100%;
    }
  }
  .video {
    height: 394px;
  }
  .slider-item-wrapper {
    padding-top: 8%;
  }
  .slider-item {
    text-align: center;
  }
  .slider-item .video-title {
    margin-bottom: 30px;
    font-size: 30px;
  }
  .section-title {
    font-size: 24px;
  }
  .slick-prev, .slick-next {
    width: 34px;
    height: 34px;
  }
  .slick-next {
    right: -52px;
  }
  .slick-prev {
    left: -52px;
  }
  .video-docs {
    padding: 0;
  }
  .coast-list li > span {
    margin-right: 10px;
  }
  .production-circle {
    min-width: 300px;
  }
  .production-circle-title span:first-child {
    font-size: 80px;
  }
  .production-circle-title span:nth-child(2) {
    font-size: 26px;
    bottom: -51%;
    left: 3%;
  }
  .circle-info-block {
    flex-direction: column;
    margin-left: 0;
  }
  .circle-info-block .icon {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .circle-info-block span:nth-child(2) {
    text-align: center;
  }
  .section-title:after {
    width: 100%;
  }
  .double-input {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
  .double-input .input-block:first-child {
    margin-right: 0;
  }
  .double-input .input-block:nth-child(2) {
    margin-left: 0;
  }
  .send-file-block span:before {
    color: $red-color;
  }
  .video-view-item {
    padding-left: 0;
  }
  .footer-contacts {
    padding-left: 35%;
  }
  .screen-btn {
    height: 40px;
    line-height: 44px;
    font-size: 16px;
  }
  .circle-info-block {
    font-size: 16px;
  }
  .production-circle-bg {
    top: -300px;
  }
  .slider-item-wrapper .slider-item {
    top: 80px;
  }
  .video-docs {
    .video-poster {
      right: 0;
      left: 0;
    }
  }
  .video-docs .video-poster.small-poster img {
    top: 7px;
  }
  .slider-item .video-description {
    line-height: 28px;
  }

  .map {
    position: relative;
    .map-bg {
      &:after {
        background: linear-gradient(to bottom, rgba(1, 3, 15, .6) 0%, rgba(0, 0, 0, .8) 50%, rgba(15, 19, 28, .1) 100%);
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .popup .section-title {
    font-size: 18px;
  }
  .logo-title {
    font-size: 24px;
  }
  .video-view-wrapper .video-view-title {
    font-size: 18px;
  }
  .video-view-wrapper .video-view-logo {
    margin-left: 0;
  }
  .partner-item {
    font-size: 14px;
  }
  .footer-contacts {
    padding-left: 30%;
  }
  .benefit-description {
    padding-left: 0;
  }
}
@media screen and (max-width: 375px) {
  .benefit-title {
    font-size: 22px;
  }
  .slider-item .video-price {
    font-size: 22px;
  }
  .benefit-item {
    width: 80%;
  }
  .section-title {
    font-size: 20px;
  }
  .contract-docs:after {
    bottom: -46px;
  }
}