@font-face {
  font-family: 'mullerbold';
  src: url('../fonts/MullerBold.otf');
  //src: url('../fonts/fontfabric_-_mullerbold-webfont.eot');
  //src: url('../fonts/fontfabric_-_mullerbold-webfont.eot?#iefix') format('embedded-opentype'),
  //     url('../fonts/fontfabric_-_mullerbold-webfont.woff') format('woff'),
  //     url('../fonts/fontfabric_-_mullerbold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'mullerlight';
  src: url('../fonts/MullerLight.otf');
  //src: url('../fonts/fontfabric_-_mullerlight-webfont.eot');
  //src: url('../fonts/fontfabric_-_mullerlight-webfont.eot?#iefix') format('embedded-opentype'),
  //     url('../fonts/fontfabric_-_mullerlight-webfont.woff') format('woff'),
  //     url('../fonts/fontfabric_-_mullerlight-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'mullermedium';
  src: url('../fonts/MullerMedium.otf');
  //src: url('../fonts/fontfabric_-_mullermedium-webfont.eot');
  //src: url('../fonts/fontfabric_-_mullermedium-webfont.eot?#iefix') format('embedded-opentype'),
  //     url('../fonts/fontfabric_-_mullermedium-webfont.woff') format('woff'),
  //     url('../fonts/fontfabric_-_mullermedium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'mullerregular';
  src: url('../fonts/MullerRegular.otf');
  //src: url('../fonts/fontfabric_-_mullerregular-webfont.eot');
  //src: url('../fonts/fontfabric_-_mullerregular-webfont.eot?#iefix') format('embedded-opentype'),
  //     url('../fonts/fontfabric_-_mullerregular-webfont.woff') format('woff'),
  //     url('../fonts/fontfabric_-_mullerregular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'icomoon';
  src:
    url('../fonts/icomoon.ttf?6ts5z8') format('truetype'),
    url('../fonts/icomoon.woff?6ts5z8') format('woff'),
    url('../fonts/icomoon.svg?6ts5z8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-statictic:before {
  content: "\e94a";
}
.icomoon-diplomat:before {
  content: "\e94b";
}
.icomoon-gamepad:before {
  content: "\e94c";
}
.icomoon-interview:before {
  content: "\e94d";
}
.icomoon-partners:before {
  content: "\e94e";
}
.icomoon-raketa:before {
  content: "\e94f";
}
.icomoon-mass-users:before {
  content: "\e949";
}
.icomoon-d-modeling:before {
  content: "\e900";
}
.icomoon-d-text:before {
  content: "\e901";
}
.icomoon-analytics:before {
  content: "\e902";
}
.icomoon-art-gallery:before {
  content: "\e903";
}
.icomoon-award:before {
  content: "\e904";
}
.icomoon-briefing:before {
  content: "\e905";
}
.icomoon-browser:before {
  content: "\e906";
}
.icomoon-calendar-with-a-clock-time-tools:before {
  content: "\e907";
}
.icomoon-call-answer:before {
  content: "\e908";
}
.icomoon-clapperboard:before {
  content: "\e909";
}
.icomoon-clock-1:before {
  content: "\e90a";
}
.icomoon-clock:before {
  content: "\e90b";
}
.icomoon-close-2:before {
  content: "\e90c";
}
.icomoon-close:before {
  content: "\e90d";
}
.icomoon-columns:before {
  content: "\e90e";
}
.icomoon-computer:before {
  content: "\e90f";
}
.icomoon-conference-hall:before {
  content: "\e910";
}
.icomoon-contract:before {
  content: "\e911";
}
.icomoon-cosmetics:before {
  content: "\e912";
}
.icomoon-cube:before {
  content: "\e913";
}
.icomoon-disney:before {
  content: "\e914";
}
.icomoon-document-1:before {
  content: "\e915";
}
.icomoon-document:before {
  content: "\e916";
}
.icomoon-drone-1:before {
  content: "\e917";
}
.icomoon-drone:before {
  content: "\e918";
}
.icomoon-employee:before {
  content: "\e919";
}
.icomoon-envelope:before {
  content: "\e91a";
}
.icomoon-factory:before {
  content: "\e91b";
}
.icomoon-file-1:before {
  content: "\e91c";
}
.icomoon-file:before {
  content: "\e91d";
}
.icomoon-folder:before {
  content: "\e91e";
}
.icomoon-function:before {
  content: "\e91f";
}
.icomoon-gear:before {
  content: "\e920";
}
.icomoon-handshake:before {
  content: "\e921";
}
.icomoon-happy-and-sad-theater-masks:before {
  content: "\e922";
}
.icomoon-headphone:before {
  content: "\e923";
}
.icomoon-invert-colors-button:before {
  content: "\e924";
}
.icomoon-language:before {
  content: "\e925";
}
.icomoon-mail-black-envelope-symbol:before {
  content: "\e926";
}
.icomoon-male-telemarketer:before {
  content: "\e927";
}
.icomoon-mansion:before {
  content: "\e928";
}
.icomoon-maps-and-flags:before {
  content: "\e929";
}
.icomoon-megaphone:before {
  content: "\e92a";
}
.icomoon-microphone:before {
  content: "\e92b";
}
.icomoon-microphone-black-shape:before {
  content: "\e92c";
}
.icomoon-movie:before {
  content: "\e92d";
}
.icomoon-multiple-users-silhouette:before {
  content: "\e92e";
}
.icomoon-music-player:before {
  content: "\e92f";
}
.icomoon-next:before {
  content: "\e930";
}
.icomoon-phone-call:before {
  content: "\e931";
}
.icomoon-photo:before {
  content: "\e932";
}
.icomoon-plan:before {
  content: "\e933";
}
.icomoon-play-button-1:before {
  content: "\e934";
}
.icomoon-play-button:before {
  content: "\e935";
}
.icomoon-plus:before {
  content: "\e936";
}
.icomoon-plus-zoom:before {
  content: "\e937";
}
.icomoon-presentation:before {
  content: "\e938";
}
.icomoon-presentation2:before {
  content: "\e939";
}
.icomoon-remote-control:before {
  content: "\e93a";
}
.icomoon-right-arrow:before {
  content: "\e93b";
}
.icomoon-sort-down:before {
  content: "\e93c";
}
.icomoon-suitcase-with-white-details:before {
  content: "\e93d";
}
.icomoon-take-off:before {
  content: "\e93e";
}
.icomoon-tick-1:before {
  content: "\e93f";
}
.icomoon-tick:before {
  content: "\e940";
}
.icomoon-translate:before {
  content: "\e941";
}
.icomoon-ui:before {
  content: "\e942";
}
.icomoon-video-camera-1:before {
  content: "\e943";
}
.icomoon-video-camera:before {
  content: "\e944";
}
.icomoon-video-conference:before {
  content: "\e945";
}
.icomoon-video-player:before {
  content: "\e946";
}
.icomoon-calendar-check-o:before {
  content: "\e947";
}
.icomoon-send:before {
  content: "\e948";
}
.icomoon-spinner6:before {
  content: "\e97f";
}
.icomoon-facebook2:before {
  content: "\ea90";
}
.icomoon-stage1:before {
  content: "\e950";
}
.icomoon-stage2:before {
  content: "\e951";
}
.icomoon-stage3:before {
  content: "\e952";
}
.icomoon-stage4:before {
  content: "\e953";
}
.icomoon-stage5:before {
  content: "\e954";
}
.icomoon-stage6:before {
  content: "\e955";
}
.icomoon-stage7:before {
  content: "\e956";
}
.icomoon-stage8:before {
  content: "\e957";
}
.icomoon-stage9:before {
  content: "\e958";
}
.icomoon-play3:before {
  content: "\ea1c";
}
.icomoon-previous2:before {
  content: "\ea23";
}
.icomoon-next2:before {
  content: "\ea24";
}
.icomoon-pause:before {
  content: "\e959";
}
.icomoon-eye:before {
  content: "\e95a";
}