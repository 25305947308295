/*
*  node_modules
*/
@import "../../node_modules/normalize.css/normalize";

/*
* Custom (from src/style/partials)
*/
@import "partials/fonts";
@import "partials/slick";
@import "partials/slick-theme";
@import "partials/app";
@import "partials/media";
