$red-color: #f22d2d;
$blue-color: #6a83bb;
$form-border: #342e39;
body {
  font-family: mullerregular, sans-serif;
  font-size: 18px;
  color: rgba(255,255,255, .9);
  min-width: 320px;
  background: #0f131c;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.mobile-menu {
  overflow: hidden;
}
.preloader-wrapper {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0f131c;
  text-align: center;
  .icon {
    display: inline-block;
    line-height: 100vh;
    font-size: 20px;
    color: $red-color;
    transform: rotate(1000deg);
    transition: all 1s;
    animation: spinner 2s infinite linear;
  }
}
@keyframes spinner {
  0%   {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
ol, ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.container {
  width: 100%;
  max-width: 1248px;
  padding: 0 15px;
  margin: 0 auto;
  //border: 1px solid green;
  box-sizing: border-box;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 15px;
}
.logo {
  text-decoration: none;
  color: #fff;
}
.logo-title {
  font-family: mullermedium, sans-serif;
  font-size: 34px;
  line-height: 41px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 4px;
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: #fff;
  }
  .red {
    color: $red-color;
  }
}
.logo-description {
  font-family: mullerlight, sans-serif;
  font-size: 18px;
  display: block;
}
.lang-block {
  display: none;
  //display: flex;
  align-items: center;
  font-family: mullermedium, sans-serif;
  margin-left: 109px;
  margin-top: -18px;
}
.lang-item {
  font-size: 18px;
  color: #3e475b;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 13px;
}
.lang-item.active {
  color: #fff;
  text-decoration: underline;
}
.contact-block {
  font-family: mullerlight, sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-right: 8px;
}
.mail-block {
  width: 82%;
  margin-top: -16px;
  position: relative;
  &:before {
    content: "\e948";
    font-family: icomoon;
    color: $red-color;
    position: absolute;
    top: -1px;
    left: -32px;
    font-size: 16px;
  }
}
.phone-block {
  padding-left: 10px;
  position: relative;
  &:before {
    content: "\e908";
    font-family: icomoon;
    color: $red-color;
    position: absolute;
    top: 3px;
    left: -25px;
    font-size: 14px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 1px;
    height: 60px;
    background: #fff;
  }
  div {
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.work-time {
  width: 50%;
  position: relative;
  margin-right: 8px;
  &:before {
    content: "\e947";
    font-family: icomoon;
    color: $red-color;
    position: absolute;
    top: 3px;
    left: -34px;
    font-size: 14px;
  }
}

.mobile-menu-btn {
  display: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid $red-color;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  span {
    display: inline-block;
    width: 26px;
    height: 2px;
    background: $red-color;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.main-menu {
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  a {
    font-family: mullermedium, sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    position: relative;
    margin: 0 37px 0 27px;
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      right: -36px;
      display: block;
      width: 1px;
      height: 13px;
      background: #3e475b;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.main-menu.fix-menu {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  background: #0f131c;
}

.video {
  width: 100%;
  height: 744px;
  position: relative;
  margin-top: 22px;
  overflow: hidden;
}
.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 6px);
  border-top: 3px solid $red-color;
  border-bottom: 3px solid $red-color;
  z-index: 1;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.video-mask {
  z-index: 2;
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  height: calc(100% - 7px);
  background: url(../img/bg-pixel.png) repeat left top;
}
.blue-bg {
  position: absolute;
  top: 3px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(52,83,126, .15);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    background: url(../img/top-video-bg.png) no-repeat 0px -107px;
    background-size: cover;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 6px;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    background: url(../img/bottom-video-bg.png) no-repeat center 0px;
    background-size: cover;
  }
}
.slider-wrapper {
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  bottom: 3px;
  background: transparent;
  z-index: 4;
  width: 100%;
  height: 100%;
  .container {
    max-width: 1150px;
  }
  * {
    outline: none;
  }
}
.slider-item-wrapper {
  padding-top: 18%;
}

.slider-item-wrapper {
  position: relative;
  .slider-item {
    position: absolute;
    right: 0;
    top: 200px;
    opacity: 0;
    transition: opacity .5s;
  }
  .slider-item.show {
    opacity: 1;
  }
}

.slider-item {
  font-family: mullerbold, sans-serif;
  text-align: right;
  .video-title {
    font-size: 53px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: -5px;
  }
  .video-description {
    font-family: mullerlight, sans-serif;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 25px;
  }
  .video-price {
    font-size: 30px;
    color: $red-color;
    margin-right: 20px;
    text-shadow: 0 0 114px rgba(1,12,48, 1);
  }
}
.view-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: auto;
  padding-bottom: 62px;
}
.video-frame {
  pointer-events: none;
  z-index: 6;
  position: absolute;
  top: 0;
  left: 0;
  bottom: -6px;
  right: 0;
}
.frame-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 40%;
  border-right: 133px solid transparent;
  border-top: 66px solid #0f131c;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    border-right: 8px solid #0000;
    border-top: 4px solid #f22d2d;
  }
}
.frame-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0;
  width: 40%;
  border-left: 117px solid transparent;
  border-bottom: 66px solid #0f131c;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 99%;
    border-left: 8px solid #0000;
    border-bottom: 4px solid $red-color;
  }
}
.video-view-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .video-view-item {
    display: flex;
    position: relative;
    &:hover {
      .video-view-logo {
        transform: scale(1.1);
      }
    }
    &:after {
      content: '';
      position: absolute;
      right: -78px;
      top: 0;
      display: block;
      width: 1px;
      height: 56px;
      background: #fff;
    }
    &:nth-child(2){
      &:after {
        right: -57px;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  .video-view-logo {
    margin-right: 45px;
    margin-left: 30px;
    transition: all .5s;
  }
  .video-view-title {
    font-family: mullerbold, sans-serif;
    font-size: 24px;
    text-transform: uppercase;
  }
  .video-view-text {
    font-family: mullerlight, sans-serif;
    font-size: 18px;
    max-width: 220px;
  }
}


.image {
  padding: 60px 0 0 0;
  position: relative;
  //  background: linear-gradient(to bottom, rgba(15,19,28,.1) 0%,rgba(7,11,20,1) 50%,rgba(15,19,28,.1) 100%);
  z-index: 3;
  .container {
    position: relative;
    z-index: 3;
  }
}

.image-bg {
  z-index: 1;
  position: absolute;
  top: 109px;
  left: 0;
  right: 0;
  height: 100%;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100vw;
  }
}
.image-gradient {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(15,19,28,.2) 0%,rgba(7,11,20,.7) 70%,rgba(15,19,28,.2) 100%);
}
.scenario {
  //overflow: hidden;
  position: relative;
  .scenario-gradient {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(15,19,28,.1) 0%,rgba(7,11,20,.8) 70%,rgba(15,19,28,.1) 100%);
  }
  &>div {
    position: relative;
    z-index: 3;
  }
  .scenario-bg {
    z-index: 1;
    position: absolute;
    bottom: 2%;
    left: 0;
    width: 100%;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 98vw;
    }
  }
}
.production {
  position: relative;
  z-index: 2;
  .container {
    position: relative;
    z-index: 3;
  }
}
.production-gradient {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(15,19,28, .5) 0%,rgba(0,9,24, .5) 100%);
}
.production-circle-bg {
  position: absolute;
  top: 120px;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 99vw;
    width: auto;
  }
}
.main-menu-wrapper {
  height: 80px;
}
.section-title {
  margin: 0;
  font-size: 36px;
  text-align: center;
  position: relative;
  margin-bottom: 90px;
  padding-top: 100px;
  margin-top: -100px;
  span {
    display: inline-block;
    width: 100%;
  }
  span:first-child {
    font-family: mullerbold, sans-serif;
    text-transform: uppercase;
  }
  span:nth-child(2) {
    font-family: mullerlight, sans-serif;
    text-transform: lowercase;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 317px;
    height: 3px;
    background: $red-color;
  }
}
.btn-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 143px;
}
.round-btn {
  width: 272px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 26px;
  background: transparent;
  border: 2px solid $red-color;
  text-align: center;
  font-family: mullermedium, sans-serif;
  cursor: pointer;
  line-height: 54px;
  text-transform: uppercase;
  transition: all .3s;
  margin: 0 64px;
  &:hover {
    background: $red-color;
  }
}
.benefit-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 110px;
  margin-bottom: 145px;
}
.benefit-title {
  font-size: 30px;
  font-family: mullerbold, sans-serif;
  color: $red-color;
  text-transform: uppercase;
  margin-bottom: 13px;
}
.benefit-logo {
  font-size: 60px;
  padding-left: 80px;
  margin-bottom: 20px;
  .icon {
    transition: all .5s;
    transform-origin: center center;
  }
}
.benefit-description {
  max-width: 300px;
  padding-left: 40px;
  p {
    margin-top: 0;
    margin-bottom: 12px;
    font-family: mullerlight, sans-serif;
    font-size: 18px;
    line-height: 22px;
  }
}
.benefit-item {
  padding-left: 20px;
  position: relative;
  &:hover {
    .icon {
      transform: scale(1.1);
      display: inline-block;
    }
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 3px;
    height: 265px;
    background: url(../img/benefit-line.png) no-repeat top center ;
  }
  &:nth-child(2) {
    margin-top: 60px;
    .benefit-logo {
      padding-left: 50px;
    }
  }
  &:nth-child(3) {
    .benefit-logo {
      padding-left: 30px;
    }
  }
}
.scenario-types {
  font-family: mullermedium, sans-serif;
  font-size: 24px;
  color: $red-color;
  margin-top: 90px;
  margin-bottom: 35px;
  .container {
    max-width: 1066px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.laptop-block {
  max-width: 1380px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  .laptop-img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }
  .laptop-screen {
    position: absolute;
    left: 15.4%;
    right: 15.4%;
    top: 7.5%;
    box-sizing: border-box;
  }
  video {
    cursor: pointer;
  }
}
.scenario-slider {
  height: 100%;
  .slick-list {
    width: calc(100% + 1px);
  }
}
.scenario-slider-item {
  height: 100%;
  .item {
    overflow: hidden;
    overflow-y: auto;
    margin-right: -15px;
    &::-webkit-scrollbar {
      width: 0;
    }
    //&::-webkit-scrollbar-track {
    //  -webkit-border-radius: 8px;
    //  border-radius: 8px;
    //  background: transparent;
    //}
    //&::-webkit-scrollbar-thumb {
    //  -webkit-border-radius: 8px;
    //  border-radius: 8px;
    //  background: $blue-color;
    //}
    img {
      display: block;
      width: 100%;
    }
  }
}
.slick-next {
  right: -160px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid $blue-color;
  box-sizing: border-box;
  &:before {
    content: "\e93b";
    font-family: icomoon;
    color: $blue-color;
  }
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: $blue-color;
}
.slick-prev {
  left: -160px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid $blue-color;
  box-sizing: border-box;
  &:before {
    content: "\e93b";
    font-family: icomoon;
    color: $blue-color;
    display: inline-block;
    transform: rotate(180deg);
  }
}
.coast-list {
  margin-bottom: 50px;
  li {
    margin-bottom: 20px;
    padding-left: 40px;
    position: relative;
  }
  li>span {
    position: absolute;
    left: 0;
    top: -6px;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $red-color;
    color: #fff;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    margin-right: 30px;
  }
  .icomoon-microphone-black-shape {
    font-size: 18px;
  }
  .icomoon-happy-and-sad-theater-masks {
    font-size: 20px;
  }
  .icomoon-mass-users {
    font-size: 12px;
  }
}
.production-circle {
  width: 43%;
  margin: 170px auto 350px;
  border: 2px dashed $blue-color;
  border-radius: 50%;
  position: relative;
}
.circle-points {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid $blue-color;
  background: #0b121d;
  box-sizing: border-box;
}
.circle-line {
  height: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: bottom center;
  &:nth-child(2){
    transform: translateX(-50%) rotate(45deg);
  }
  &:nth-child(3){
    transform: translateX(-50%) rotate(90deg);
  }
  &:nth-child(4){
    transform: translateX(-50%) rotate(135deg);
  }
  &:nth-child(5){
    transform: translateX(-50%) rotate(180deg);
  }
  &:nth-child(6){
    transform: translateX(-50%) rotate(225deg);
  }
  &:nth-child(7){
    transform: translateX(-50%) rotate(270deg);
  }
  &:nth-child(8){
    transform: translateX(-50%) rotate(315deg);
  }
  .circle-points {
    position: relative;
    top: -10px;
  }
}

.production-circle-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: $red-color;
  span:first-child {
    font-family: mullermedium, sans-serif;
    font-size: 151px;
  }
  span:nth-child(2){
    font-family: mullerlight, sans-serif;
    font-size: 36px;
    position: absolute;
    bottom: -28%;
    left: 18%;
  }
}

.circle-info-wrapper {
  width: 100%;
  height: 100%;

}

//inner styles

.circle-info-block {
  //border: 1px solid yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    color: $red-color;
    font-size: 50px;
    margin-right: 20px;
    display: inline-block;
    transition: all .5s;
  }
  span:nth-child(2) {
    white-space: nowrap;
    line-height: 22px;
  }
  &:hover {
    .icon {
      transform: scale(1.1);
    }
  }
}
.screen-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% + 1px);
  height: 66px;
  background: rgba(0,0,0, .51);
  text-align: center;
  line-height: 66px;
  color: #fff;
  font-family: mullerlight, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .3s;
  &:hover {
    background: rgba(0,0,0, .75);
  }
}
.screen-btn.for-video {
  display: none;
}
.video-scenario-slider {
  display: none;
  //border: 1px solid red;
  height: 100%;
}

.laptop-screen.show-video {
  .scenario-slider {
    .item {
      display: none;
    }
    .video-scenario-slider-item {
      display: block;
      width: 100%;
    }
  }
  .screen-btn.for-slider {
    display: none;
  }
  .screen-btn.for-video {
    display: block;
  }
}
.slick-list, .slick-track {
  height: 100%;
}
.video-scenario-slider-item {
  //display: none;
  display: block;
  height: 100%;
  width: 100%;
  video {
    //width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.timeline {
  padding-bottom: 40px;
  margin-bottom: 90px;
  position: relative;
  .timeline-gradient {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: -200px;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(0,0,0, .1) 0%,rgba(7,11,20,.9) 70%,rgba(15,19,28, .99) 100%);
  }
  .container {
    position: relative;
    z-index: 2;
  }
}
.timeline-slider, .timeline-btn-block {
  display: none;
}
.timeline-row {

  .line {
    width: 100%;
    height: 11px;
    background: #182538;
    position: relative;
    border-radius: 20px;
  }
  .point {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $blue-color;
    position: relative;
    top: -1px;
    &:first-child {
      left: -2px;
    }
    &:last-child {
      right: -2px;
    }
  }
  .part {
    width: 14.285%;
    height: 100%;
    background: transparent;
    //border: 1px solid #fff;
    box-sizing: border-box;
    float: left;
    display: flex;
    justify-content: flex-start;
    &:first-child {
      display: none;
    }
  }
  .inner {
    width: 0;
    height: 100%;
  }
}
.part.active {
  .inner {
    width: 100%;
    background: $red-color;
    transition: all 1s;
  }
}

.line-items {
  display: flex;
  text-align: center;
  margin-left: -6%;
  margin-right: -6%;
  box-sizing: border-box;

  .item {
    max-width: 12.5%;
    min-width: 12.5%;
    opacity: 0;
    transition: opacity 1s;
    will-change: opacity;
    margin-bottom: 20px;
    &.active {
      opacity: 1;
    }
    .icon {
      font-size: 60px;
      color: $red-color;
      margin-bottom: 20px;
      display: block;
    }
  }
  .item-title {
    white-space: nowrap;
    line-height: 22px;
  }
}

.line-points {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.city-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: 0 auto 20px;
  .city-item {
    &:before {
      content: "\e93f";
      font-family: icomoon;
      color: $blue-color;
      font-size: 20px;
      margin-right: 20px;
    }
  }
}

.map {
  position: relative;
  //.top-map-gradient {
  //  background: linear-gradient(to bottom, rgba(1,3,15,.1) 0%,rgba(0,0,0,.6) 50%,rgba(15,19,28,.1) 100%);
  //  position: absolute;
  //  top: -30%;
  //  left: 0;
  //  width: 100%;
  //  height: 50%;
  //  z-index: 1;
  //}
  .map-bg {
    position: absolute;
    z-index: 0;
    top: -140px;
    left: 0;
    width: 100%;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 60%;
      position: absolute;
      top: -33%;
      left: 0;
      background: linear-gradient(to bottom, rgba(1,3,15,.1) 0%, rgba(0,0,0,.9) 25%, rgba(0,0,0,.9) 50%,rgba(15,19,28,.1) 100%);
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 80%;
      position: absolute;
      top: 66%;
      left: 0;
      background: linear-gradient(to bottom, rgba(1,3,15,.4) 0%,rgba(0,0,0,.8) 50%,rgba(15,19,28,.1) 100%);
    }
    img {
      display: block;
      width: 100%;
    }
  }
  .map-gradient {
    position: absolute;
    z-index: 0;
    bottom: 50px;
    left: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(to bottom, rgba(1,3,15,.1) 0%,rgba(0,0,0,.8) 50%,rgba(15,19,28,.1) 100%);
  }
  .container {
    position: relative;
    z-index: 3;
  }
}
button[pseudo="-internal-media-controls-overflow-button"] {
  display: none;
}


.map-block {
  max-width: 760px;
  margin: 0 auto 75px;
  img {
    display: block;
    width: 100%;
  }
}
.map-text {
  text-align: center;
  font-size: 24px;
  margin-bottom: 100px;
}
.stages {
  margin-bottom: 120px;
  position: relative;
  .right-camera {
    position: absolute;
    right: 0;
    top: -220px;
    z-index: 3;
  }
  .left-camera {
    z-index: 3;
    position: absolute;
    left: 0;
    top: -250px;
  }
  .stages-gradient {
    position: absolute;
    z-index: 2;
    top: -265px;
    left: 0;
    width: 100%;
    height: 140%;
    background: linear-gradient(to bottom, rgba(1,3,15,.2) 0%,rgba(0,0,0,.7) 50%,rgba(15,19,28,.1) 100%);
  }
  .container {
    position: relative;
    z-index: 3;
  }
}
.stages-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1060px;
  margin: 0 auto;

}
.stages-item-wrapper {
  width: 33.33%;
}
.stages-item {
  margin-bottom: 50px;
  padding-top: 70px;
  position: relative;
  span {
    padding-left: 20px;
    position: relative;
    display: inline-block;
    &:before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 56px;
      background: url("../img/stage-line.png") no-repeat bottom center;
      position: absolute;
      bottom: 5px;
      left: 0;

    }
  }
  &:before {
    display: inline-block;
    position: absolute;
    font-family: icomoon;
    left: 0;
    top: 5px;
    color: $blue-color;
    font-size: 46px;
    transition: all .5s;
  }
  &:hover:before {
    transform: scale(1.1);
  }
  &.stage1 {
    &:before {
      content: "\e950";
    }
  }
  &.stage2 {
    &:before {
      content: "\e951";
    }
  }
  &.stage3 {
    &:before {
      content: "\e952";
    }
  }
  &.stage4 {
    &:before {
      content: "\e953";
    }
  }
  &.stage5 {
    &:before {
      content: "\e954";
    }
  }
  &.stage6 {
    &:before {
      content: "\e955";
    }
  }
  &.stage7 {
    &:before {
      content: "\e956";
    }
  }
  &.stage8 {
    &:before {
      content: "\e957";
    }
  }
  &.stage9 {
    &:before {
      content: "\e958";
    }
  }
}
.stages-text {
  line-height: 21px;
}
.stage-time {
  text-align: center;
  span {
    font-family: mullermedium, sans-serif;
    color: $red-color;
  }
}

.coast-wrapper {
  display: flex;
  margin-bottom: 140px;
}
.coast-item {
  width: 33.33%;
  position: relative;
  padding-bottom: 50px;
  padding-left: 35px;
  box-sizing: border-box;
  border-right: 2px solid #171e2d;
  &:last-child {
    border-right: none;
  }
}
.coast-item-title {
  color: $red-color;
  text-align: center;
  margin-bottom: 10px;
  min-height: 46px;
  span:first-child {
    font-family: mullermedium, sans-serif;
    font-size: 20px;
    text-transform: uppercase;
  }
  span:nth-child(2){
    font-size: 20px;
    display: block;
  }
}
.coast-item-time{
  text-align: center;
  margin-bottom: 24px;
}
.coast-item-text {
  margin-bottom: 20px;
  padding-left: 30px;
}
.coast-item-time, .coast-item-text {
  font-family: mullermedium, sans-serif;
}
.coast-item-list {
  font-family: mullerlight, sans-serif;
  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    &:before {
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $red-color;
      position: absolute;
      top: 6px;
      left: 0;
    }
  }
}
.coast-btn {
  width: 215px;
  height: 43px;
  background: $red-color;
  color: #fff;
  cursor: pointer;
  font-family: mullermedium, sans-serif;
  text-align: center;
  line-height: 44px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.communication-method {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 35px;
  .mail-block {
    width: auto;
  }
}
.communication-title {
  font-family: mullermedium, sans-serif;
  span:first-child {
    font-size: 24px;
    color: $red-color;
    text-transform: uppercase;
    display: block;
  }
}
.tab-row {
  display: flex;
  justify-content: flex-start;
  .tab-item {
    width: 206px;
    height: 44px;
    box-sizing: border-box;
    line-height: 44px;
    text-align: center;
    color: #fff;
    border: 1px solid $form-border;
    text-decoration: underline;
    font-family: mullerlight, sans-serif;
    cursor: pointer;
    margin-right: 7px;
    transition: all .3s;
    &.active {
      border-color: $red-color;
      background: $red-color;
      text-decoration: none;
    }
  }
}

.know-coast {
  margin-bottom: 90px;
  position: relative;
  z-index: 1;

  .know-coast-gradient {
    position: absolute;
    z-index: 2;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(1,3,15,.01) 0%,rgba(0,0,0,.7) 50%,rgba(15,19,28,.5) 100%);
  }
  .container {
    position: relative;
    z-index: 3;
  }

  .small-container {
    max-width: 840px;
    padding-top: 40px;
  }
}

.form-know-coast {
  padding: 50px 0;
}
.input-block {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
  input {
    margin-top: 5px;
  }
}
form {
  display: flex;
  flex-wrap: wrap;
  label {
    color: #707070;
    font-family: 'mullerlight', sans-serif;
  }
  input {
    color: #fff;
    padding: 0 15px;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
    background: transparent;
    border: 1px solid $form-border;
    outline: none;
  }
  textarea {
    margin-top: 5px;
    width: 100%;
    resize: none;
    min-height: 80px;
    background: transparent;
    border: 1px solid $form-border;
    outline: none;
    color: #fff;
    padding: 5px 15px;
    box-sizing: border-box;
  }
  input[type=submit]{
    width: 100%;
    height: 44px;
    background: $red-color;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
    margin-bottom: 25px;
  }
  p {
    text-align: center;
    width: 100%;
    margin: 0;
    font-family: mullerlight, sans-serif;
    font-size: 16px;
  }
  input[type=file]{
    display: none;
  }
}

.double-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .input-block {
    &:first-child {
      margin-right: 20px;
    }
    &:nth-child(2){
      margin-left: 20px;
    }
  }
}

.send-file-block {
  text-align: center;
  font-family: mullerlight, sans-serif;
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed $form-border;
  margin-top: 5px;
  margin-bottom: 35px;
  font-size: 14px;
  cursor: pointer;
  span {
    &:before {
      content: "\e936";
      font-family: icomoon;
      font-size: 16px;
      margin-right: 12px;
      position: relative;
      top: 2px;
    }
  }
}

.custom-checkbox {
  cursor: pointer;
  margin-right: 15px;
  input[type=checkbox]{
    display: none;
  }
  .new-checkbox {
    display: inline-block;
    width: 17px;
    height: 17px;
    border-radius: 2px;
    border: 1px solid $form-border;
    box-sizing: border-box;
  }
  input[type=checkbox]:checked + .new-checkbox {
    position: relative;
    &:before {
      content: "\e940";
      font-family: icomoon;
      font-size: 20px;
      color: $red-color;
      top: -6px;
      left: 2px;
      position: absolute;
    }
  }
}
.custom-checkbox + span {
  font-size: 14px;
  font-family: mullerlight, sans-serif;
  color: #707070;
  a {
    color: $red-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.accepted-block {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.download-link {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: $red-color;
  font-family: mullermedium,sans-serif;
  font-size: 16px;
  margin-top: 15px;
  &:hover {
    text-decoration: underline;
  }
}

.partners {
  position: relative;
  z-index: 1;
  .partners-gradient {
    position: absolute;
    z-index: 2;
    top: -130px;
    left: 0;
    width: 100%;
    height: 88%;
    background: linear-gradient(to bottom, rgba(1,3,15,.1) 0%,rgba(0,0,0,.7) 50%,rgba(15,19,28,.09) 100%);
  }
  .container {
    position: relative;
    z-index: 3;
  }
}


.partner-logo-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
  .partner-logo {
    width: 16.66%;
    height: 130px;
    //border: 1px solid red;
    box-sizing: border-box;
    div {
      background-image: url('../img/partner-sprite.png');
      background-repeat: no-repeat;
      margin: 0 auto;
      height: 100%;
    }
    &.short-logo {
      height: 100px;
    }
  }
  .partner1 {
    background-position: 0 -4px;
    width: 175px;
  }
  .partner2 {
    background-position: -266px -4px;
    width: 73px;
  }
  .partner3 {
    background-position: -455px 0;
    width: 104px;
  }
  .partner4 {
    background-position: -630px -4px;
    width: 167px;
  }
  .partner5 {
    background-position: -857px -14px;
    width: 134px;
  }
  .partner6 {
    background-position: -1053px -16px;
    width: 162px;
  }
  .partner7 {
    background-position: -3px -133px;
    width: 174px;
  }
  .partner8 {
    background-position: -251px -134px;
    width: 95px;
  }
  .partner9 {
    background-position: -434px -136px;
    width: 153px;
  }
  .partner10 {
    background-position: -667px -118px;
    width: 102px;
  }
  .partner11 {
    background-position: -879px -137px;
    width: 90px;
  }
  .partner12 {
    background-position: -1057px -143px;
    width: 156px;
  }
  .partner13 {
    background-position: -5px -259px;
    width: 172px;
  }
  .partner14 {
    background-position: -230px -255px;
    width: 141px;
    height: 111px;
  }
  .partner15 {
    background-position: -414px -260px;
    width: 181px;
  }
  .partner16 {
    background-position: -654px -260px;
    width: 120px;
  }
  .partner17 {
    background-position: -832px -266px;
    width: 185px;
  }
  .partner18 {
    background-position: -1069px -267px;
    width: 140px;
  }
  .partner19 {
    background-position: -10px -400px;
    width: 149px;
  }
  .partner20 {
    background-position: -213px -400px;
    width: 175px;
  }
  .partner21 {
    background-position: -437px -393px;
    width: 154px;
  }
  .partner22 {
    background-position: -642px -403px;
    width: 148px;
  }
  .partner23 {
    background-position: -853px -400px;
    width: 150px;
  }
  .partner24 {
    background-position: -1047px -400px;
    width: 177px;
  }
}
.partner-items-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  margin-bottom: 150px;
}
.partner-item {
  width: 33.33%;
  box-sizing: border-box;
  padding-left: 20px;
  position: relative;
  white-space: nowrap;
  max-width: 300px;
  &:before {
    content: '';
    display: block;
    width: 2px;
    height: 136px;
    background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    bottom: 23px;
    left: 0;
    position: absolute;
  }
  &:after {
    font-family: icomoon;
    font-size: 50px;
    color: #f22d2d;
    position: absolute;
    top: -73px;
    left: 20px;
    display: inline-block;
    transition: all .5s;
    transform-origin: center center;
  }
  &.item1 {
    &:after {
      content: "\e91d";
    }
  }
  &.item2 {
    &:after {
      content: "\e91e";
    }
  }
  &.item3 {
    &:after {
      content: "\e916";
    }
  }
  &:hover:after {
    transform: scale(1.1);
  }
}
.cases {
  margin-bottom: 90px;
  .container {
    position: relative;
    z-index: 2;
  }
}
.case-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.scenario {
  padding-bottom: 90px;
}
.scenario-docs {
  order: 1;
  width: 25%;
  position: relative;
  height: 354px;
  &:before {
    content: attr(data-text);
    font-family: mullermedium, sans-serif;
    font-size: 18px;
    color: $red-color;
    position: absolute;
    top: -30px;
    left: 40px;
  }
  .doc1 {
    max-width: 170px;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.5);
    img {
      display: block;
      width: 100%;
    }
  }
  .doc2 {
    max-width: 170px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.5);
    img {
      display: block;
      width: 100%;
    }
  }
}

.scenario-docs.three-doc {
  .doc1 {
    z-index: 1;
    box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.5);
  }
  .doc3 {
    width: 170px;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.5);
    img {
      display: block;
      width: 100%;
    }
  }
  .doc3 {
    z-index: 3;
    box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.5);
  }
}
.contract-docs {
  order: 3;
  width: 25%;
  height: 354px;
  position: relative;
  &:before {
    content: attr(data-text1);
    font-family: mullermedium, sans-serif;
    font-size: 18px;
    color: $red-color;
    position: absolute;
    top: -30px;
    left: 40px;
  }
  &:after {
    content: attr(data-text2);
    font-family: mullermedium, sans-serif;
    font-size: 18px;
    color: $red-color;
    position: absolute;
    bottom: -30px;
    right: 0;
  }
  .doc1 {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.5);
    img {
      display: block;
    }
  }
  .doc2 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.5);
    img {
      display: block;
    }
  }
}

.case-title {
  font-family: mullerbold, sans-serif;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}
.case-description {
  margin-bottom: 50px;
}
.case-description-title {
  font-family: mullermedium, sans-serif;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: $red-color;
}
.case-description-text {
  font-family: mullerlight, sans-serif;
  margin-bottom: 10px;
}
.case-description-btn {
  cursor: pointer;
  color: $blue-color;
  margin-bottom: 15px;
  &:after {
    content: "\e93c";
    font-family: icomoon;
    color: $blue-color;
    font-size: 12px;
    margin-left: 10px;
  }
  span {
    &:hover {
      text-decoration: underline;
    }
  }
}
.case-description-btn.open {
  &:after {
    display: inline-block;
    transform: rotate(180deg);
  }
}
.addition-block {
  display: none;
}
.mobile-case {
  display: none;
  order: 2;
  width: 100%;
  .icon {
    color: $blue-color;
    font-size: 20px;
    margin-right: 20px;
  }
  .mobile-case-btn {
    margin-bottom: 20px;
    color: $red-color;
    cursor: pointer;
  }
}
.mobile-case-content {
  display: none;
}
.coast {
  position: relative;
  z-index: 1;
  .coast-gradient {
    position: absolute;
    z-index: 2;
    top: -115px;
    left: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(to bottom, rgba(1,3,15,.02) 0%,rgba(0,0,0,.5) 50%,rgba(15,19,28,.1) 100%);
  }
  .container {
    position: relative;
    z-index: 3;
  }
}

.case-coast {
  margin-bottom: 10px;
  &+div {
    margin-bottom: 10px;
  }
  span {
    font-family: mullermedium, sans-serif;
    color: $red-color;
  }
}

.video-docs {
  cursor: pointer;
  position: relative;
  order: 2;
  width: 59%;
  padding: 0 12px;
  box-sizing: border-box;
  .video-poster {
    position: absolute;
    top: 0;
    left: 12px;
    bottom: 0;
    right: 12px;
    z-index: 1;
    img {
      display: block;
      width: 100%;
    }
    &.small-poster {
      img {
        position: relative;
        top: 12px;
      }
    }
  }
  video {
    width: 100%;
  }
  .video-play-btn {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    max-width: 600px;
    margin: 0 auto;
    &:before {
      content: "\e935";
      font-family: icomoon;
      font-size: 60px;
      color: rgba(255,255,255, .5);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .5s;
    }
    &:hover:before {
      color: rgba(255,255,255, .8);
    }
  }
}
.video-docs.play {
  .video-play-btn:before, .video-poster {
    display: none;
  }
}










footer {
  background: #01050a;
  display: flex;
  padding-top: 30px;
  border-top: 3px solid $red-color;
  box-sizing: border-box;
  position: relative;
  .frame-bottom {
    bottom: auto;
    top: -66px;
    border-bottom: 66px solid #01050a;
  }
  .frame-bottom:before {
    width: 99%;
    left: -2px;
  }
  .logo {
    margin-right: 35px;
  }
  .main-menu {
    justify-content: flex-start;
    a {
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .work-time {
    width: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .mail-block {
    width: auto;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .container {
    position: relative;
  }
  .phone-block {
    margin-bottom: 15px;
  }
  .phone-block:before {
    left: -30px;
  }
}
.footer-logo {
  display: flex;
  justify-content: flex-start;
}
.footer-logo-text {
  text-align: justify;
  font-size: 14px;
  font-family: mullerlight, sans-serif;
  max-width: 540px;
}
.footer-copy {
  font-size: 14px;
  font-family: mullerlight, sans-serif;
  text-transform: uppercase;
  padding-bottom: 15px;
}
.footer-contacts {
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: -50px;
  z-index: 2;
  font-family: mullerlight, sans-serif;
  address {
    font-style: normal;
    position: relative;
    &:before {
      content: "\e929";
      font-family: icomoon;
      color: $red-color;
      position: absolute;
      left: -30px;
      top: 0;
    }
  }
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0, .6);
  z-index: 19;
}
.popup-overlay {
  display: none;
}
.popup {
  display: none;
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(to bottom, rgba(10,15,25,1) 1%,rgba(3,6,13,1) 100%);
  z-index: 20;
  padding: 30px;
  width: 700px;
  height: calc(100% - 100px);
  overflow-y: auto;
  box-sizing: border-box;
  .section-title {
    font-size: 20px;
    margin-bottom: 70px;
  }
  .form-know-coast {
    padding: 0;
  }
  .popup-close-btn {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
    z-index: 21;
    color: rgba(255,255,255, .5);
    transition: all .5s;
    &:hover {
      color: rgba(255,255,255, .9);
    }
    &:before {
      content: "\e90c";
      font-family: icomoon;
      font-size: 20px;
    }
  }
}
body.mobile-menu {
  .mobile-menu-btn {
    border-color: #fff;
    span {
      transform-origin: center center;
      margin-bottom: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      background: #fff;
    }
    span:first-child {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: translate(-50%, -50%)rotate(-45deg);
    }
  }
}




